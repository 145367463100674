import React, { useEffect } from "react";
import * as THREE from "three"; // Import Three.js
import { useNavigate } from "react-router-dom";

function Experience() {
  const navigate = useNavigate();
  useEffect(() => {
    // Get the container element where the WebGL canvas will be added
    const container = document.getElementById("viewer-container");
    if (!container) return;

    // Create a new Three.js scene
    const scene = new THREE.Scene();

    // Create a camera with a perspective view
    const camera = new THREE.PerspectiveCamera(
      100,
      container.clientWidth / container.clientHeight,
      0.1,
      1000
    );

    // Create a WebGL renderer and set its size to fill the container
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(renderer.domElement);

    // Load the 360° image as a texture
    const textureLoader = new THREE.TextureLoader();
    const sphereGeometry = new THREE.SphereGeometry(500, 60, 40);

    // Invert the geometry to allow viewing the texture from inside the sphere
    sphereGeometry.scale(-1, 1, 1);

    // Create a material for the sphere and map the loaded texture onto it
    const sphereMaterial = new THREE.MeshBasicMaterial({
      map: textureLoader.load("/home1/assets/img/balcony.jpg"), // Replace with your 360° image path
    });

    // Create a mesh with the sphere geometry and material, then add it to the scene
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    scene.add(sphere);

    // Position the camera slightly inside the sphere
    camera.position.set(0, 0, 0.1);

    // Auto-rotation speed
    let lon = 200;
    const autoRotateSpeed = 0.05;

    // Animation loop with auto-rotation
    function animate() {
      requestAnimationFrame(animate);
      lon += autoRotateSpeed;

      const phi = THREE.MathUtils.degToRad(90);
      const theta = THREE.MathUtils.degToRad(lon);

      camera.lookAt(
        500 * Math.sin(phi) * Math.cos(theta),
        500 * Math.cos(phi),
        500 * Math.sin(phi) * Math.sin(theta)
      );

      renderer.render(scene, camera);
    }

    animate();

    // Handle resizing
    const handleResize = () => {
      camera.aspect = container.clientWidth / container.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(container.clientWidth, container.clientHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to prevent memory leaks
    return () => {
      window.removeEventListener("resize", handleResize);
      container.removeChild(renderer.domElement);
      sphereGeometry.dispose();
      sphereMaterial.dispose();
      renderer.dispose();
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <section className="tc-experience-style1 ">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 exp_txt">
            <img src="/home1/assets/img/360.png" className="threesixtyicon" />
            <h2>Experience the Future of Living</h2>
            <p>
              Explore our 360° view to immerse yourself in the luxury of this
              space.
            </p>
            <button
              className="interiorbtn"
              onClick={() => navigate("/innerpages/about")}
            >
              Exterior
            </button>
            <button
              className="exteriorbtn"
              onClick={() => navigate("/innerpages/blog")}
            >
              Interior
            </button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>

      {/* Container for the 360° view */}
      <div
        id="viewer-container"
        style={{
          width: "100%",
          height: "500px",
          position: "absolute",
          top: "0",
        }}
      ></div>

      {/* Decorative Line */}
      <img
        src="/home1/assets/img/c_line.png"
        alt="decorative line"
        className="c-line wow"
      />
    </section>
  );
}

export default Experience;
