import React from "react";

function Team() {
  return (
    <section className="tc-team-style1">
      <div className="container">
        <div className="mb-20 js-splittext-lines">
          <h2 className=" text-center areahead"> Area </h2>
        </div>

        <div className="numbers">
          <div className="row gx-5">
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.2s">
                <h3 className="perc"> </h3>
                <p>
                  {" "}
                  Stilt Floor<br></br>
                  Car Park & Entry{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.4s">
                <h3 className="perc">
                  {" "}
                  3682<span>SFT</span>{" "}
                </h3>
                <p> Ground Floor</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.6s">
                <h3 className="perc">
                  {" "}
                  3682 <span>SFT</span>{" "}
                </h3>
                <p> First Floor</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.6s">
                <h3 className="perc">
                  {" "}
                  3682 <span>SFT</span>{" "}
                </h3>
                <p> Second Floor</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.6s">
                <h3 className="perc">
                  {" "}
                  3682 <span>SFT</span>{" "}
                </h3>
                <p> Third Floor</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.6s">
                <h3 className="perc"> </h3>
                <p> Terrace Recreation & Utility</p>
              </div>
            </div>

            <div className="mb-20 js-splittext-lines mt-50">
              <h2 className=" text-center areahead"> Floor plan </h2>
            </div>
            <img src="/home1/assets/img/floor_plan.jpg" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
