import React from "react";
import data from "../../data/home1/awards";
function Awards() {
  return (
    <section className="tc-awards-style1">
      <div className="container">
        <div className="awards-content">
          <div className="mb-80 text-center js-splittext-lines">
            <h2 className="fsz-45 areahead white"> Specifications </h2>
          </div>
          <div className="awards-list wow fadeInUp" data-wow-delay="0.2s">
            {data.map((item, i) => (
              <div key={i} className="award-row">
                <div className="row gx-0 align-items-center">
                  <div className="col-lg-2">
                    <div className="title"> {item.title} </div>
                  </div>
                  <div className="col-lg-6 my-3 my-lg-0">
                    <h6 className="title fsz-23 fw-400 year">{item.year}</h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Awards;
