import React from "react";

function Footer() {
  return (
    <footer className="tc-footer-style1">
      <div className="container">
        <div className="top-content section-padding">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="info-side">
                <div className="text fsz-14 color-333 lh-3 fw-600">
                  This brochure is purely conceptual and does not constitute a
                  legal document. Visual representations are not to be taken as
                  representations of fact and do not form part of any offer or
                  contract. The management of Indraprastha Shelters, receives
                  the right to add, delete or alter any or all of the
                  specifications, areas, dimensions and content herein at its
                  discretion, without prior notice.
                </div>
                <div className="foot-social mt-50">
                  <h2 className="fsz-45 areahead "> Statutory Approvals</h2>
                  <img
                    src="/home1/assets/img/bbmp.png"
                    className="approvalimg"
                  />
                  <img
                    src="/home1/assets/img/indiagreen.png"
                    className="approvalimg"
                  />
                  <img
                    src="/home1/assets/img/credai.png"
                    className="approvalimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13">© 2025 indraprastha. All Right Reserved</p>
            </div>
            <div className="col-lg-6">
              <div className="foot-links mt-4 mt-lg-0">
                <a href="#"> Experience </a>
                <a href="#"> Area </a>
                <a href="#"> Floorplan </a>
                <a href="#"> Specifications </a>
                <a href="#"> Location </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
