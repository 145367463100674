import React from "react";

function Chat() {
  return (
    <section className="tc-chat-style1">
      <div className="container">
        <div className="content">
          <a href="#" className="xl-text">
            info@indraprastha.in
          </a>
          <a href="tel:8880777000" className="xl-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M22 16.92V19a2 2 0 0 1-2.18 2 19.86 19.86 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6A19.86 19.86 0 0 1 2 4.18 2 2 0 0 1 4 2h2.08a2 2 0 0 1 2 1.72 12.31 12.31 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L7.1 10.14a16 16 0 0 0 6.76 6.76l1.5-1.23a2 2 0 0 1 2.11-.45 12.31 12.31 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>{" "}
            &nbsp; 88807 77000
          </a>
          <a href="tel:9900025696" className="xl-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M22 16.92V19a2 2 0 0 1-2.18 2 19.86 19.86 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6A19.86 19.86 0 0 1 2 4.18 2 2 0 0 1 4 2h2.08a2 2 0 0 1 2 1.72 12.31 12.31 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L7.1 10.14a16 16 0 0 0 6.76 6.76l1.5-1.23a2 2 0 0 1 2.11-.45 12.31 12.31 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
            &nbsp; 99000 25696
          </a>
          <h5 className="mb-50 lh-5">
            Let us help your dream <br /> become reality
          </h5>
        </div>
      </div>
      <img src="/home1/assets/img/c_line4.png" alt="" className="c-line wow" />
    </section>
  );
}

export default Chat;
