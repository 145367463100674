import React from "react";
import { useNavigate } from "react-router-dom";

const Viewer360Component = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ margin: 0, padding: 0, position: "relative", height: "90dvh" }}
    >
      <button
        onClick={() => navigate("/home1")}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          color: "white",
          background: "#d1aa5d",
          padding: "10px 20px",
          zIndex: 20,
          cursor: "pointer",
          border: "none",
          textDecoration: "none",
          borderRadius: "5px",
        }}
      >
        Back
      </button>

      <div
        style={{
          margin: 0,
          padding: 0,
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          background: "black",
        }}
      >
        <button
          onClick={() => navigate("/home1")}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            background: "#d1aa5d",
            padding: "10px 20px",
            zIndex: 100,
            cursor: "pointer",
            border: "none",
            textDecoration: "none",
            borderRadius: "5px",
          }}
        >
          Back
        </button>

        {/* Video Player */}
        <video
          loop
          playsInline
          controls
          controlsList="nodownload"
          className="video-fullscreen"
          src="/interior.mp4" // Ensure this file is inside the 'public/assets' folder
          poster="/assets/img/vid_img.png"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100dvh",
            objectFit: "cover",
          }}
        ></video>
      </div>
    </div>
  );
};

const buttonStyle = {
  padding: "15px 30px",
  backgroundColor: "#d1aa5d",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "12spx",
  fontWeight: "bold",
};

export default Viewer360Component;
