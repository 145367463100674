// VideoBackground.js
import React from "react";
import { useSpring, animated } from "react-spring";
import "./VideoBackground.css";

const VideoBackground = () => {
  // Spring animation for the text
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 2000 },
  });

  return (
    <div className="video-background-container">
      {/* Fullscreen video background */}
      <video
        autoPlay="true"
        loop="true"
        muted="muted"
        playsInline="true"
        controlsList="nodownload"
        className="video-background"
        src="/home1/assets/Kria_2K.mp4"
        poster="/home10/assets/img/vid_img.png"
      ></video>

      {/* Overlay text
      <animated.div style={fadeIn} className="overlay-text">
        <img src="/home1/assets/img/banner_img.png" />
      </animated.div> */}
    </div>
  );
};

export default VideoBackground;
